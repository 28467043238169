<template>
  <div>
    <el-card class='box-card'>

      <div slot='header' class='clearfix' v-if='showHeader'>
        <div v-if='info.id'>
              <span style='font-size: 18px;font-weight: bold;'>【确认选品】{{
                  `${$utils.parseTime(info.start_at, '{y}-{m}-{d}')} ${info.nickname}
                                ${info.platform_name || info.platform_code} ${info.theme}`
                }}</span> (发起人：{{ info.leader_name }} 进度：<label
          :style='`text-align: center;border-radius: 5px;padding:2px 8px;margin:1px;border: #7d8086 1px solid;background-color:${progressStatusColorMap[info.progress_status]}`'>{{ progressStatusMap[info.progress_status] || '--'
          }}</label>)
        </div>
      </div>
      <!--      筛选条件-->
      <el-row :gutter='15'>
        <el-col :span='24'>
          <el-form size='small' inline>
            <el-form-item v-if='userPermissions.indexOf("lb_schedule_ack_inner")>-1'>
              <el-button type='primary' icon='el-icon-shopping-cart-full' @click='showPreCard'>
                确认初选选品
              </el-button>
            </el-form-item>
            <el-form-item v-if='userPermissions.indexOf("lb_schedule_ack_rm")>-1'>
              <el-button plain type='warning' icon='el-icon-remove' :disabled='multiple'
                         @click='cancelTagSelection'
                         title='移除后变为已初选未确认商品'>
                批量移除({{ ids.length }})
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-select v-model='searchCondition.leader_id' filterable
                         v-if="userPermissions.indexOf('lb_schedule_ack_list_all')>-1" allow-create
                         placeholder='产品负责人' @change='getAckSelectionList' clearable>
                <el-option :label='item.leader_name' :value='item.leader_id' v-for='(item,index) in searchLeaderOptions'
                           :key='index'>
                </el-option>
              </el-select>
              <span v-else>负责人：{{ userInfo.nickname }}</span>
            </el-form-item>
            <el-form-item>
              <el-select v-model='searchProductTitle' filterable allow-create placeholder='商品名称(可自定义)' clearable>
                <el-option :label='item' :value='item' v-for='(item,index) in searchTitleOptions' :key='index'>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' @click='init' :disabled='tableLoading'
                         :loading='tableLoading'>搜索
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-popover
                placement='right'
                width='400'
                trigger='click' @hide='hideTotalDiv'>
                <div>
                  <div class='total-form'>
                    <el-form ref='elForm' :model='info' size='small' :rules='rules' :inline='false'
                             label-width='120'>
                      <el-form-item label='红人' prop='nickname'>
                        <span> {{ info.nickname }}</span>
                      </el-form-item>
                      <el-form-item label='排期' prop='lb_id'>
                        {{
                          `${$utils.parseTime(info.start_at, '{y}-{m}-{d}')} ${info.nickname}
                                ${info.platform_name || info.platform_code} ${info.theme}`
                        }}
                      </el-form-item>
                      <el-form-item label='' label-width='60'>
                        <div class='default-table'>
                          <el-table :data='categoryStatistics' :show-header='false' show-summary sum-text='选品总数' border>
                            <el-table-column label='类目' prop='category' align='center'
                                             min-width='60'></el-table-column>
                            <el-table-column label='数量' prop='total' align='center'
                                             min-width='100'></el-table-column>
                          </el-table>
                        </div>
                      </el-form-item>
                    </el-form>
                    <el-row style='margin-bottom: 20px'>
                      <el-col :span='24'>
                        最后保存时间： {{ info.selected_time || '-' }}
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <el-button slot='reference' @click='showForm=!showForm'>汇总合计&nbsp;
                  <!--                  <i :class="[showForm?'el-icon-arrow-right':'el-icon-arrow-left']" style="size: 1.2em"></i>-->
                  <i class='el-icon-arrow-right' v-if='showForm'></i>
                  <i class='el-icon-arrow-left' v-else></i>
                </el-button>
                <!--                    <el-button slot="reference">click 激活</el-button>-->
              </el-popover>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' @click='modifyBtn' v-loading.fullscreen.lock='loading'
                         :icon="showSaveBtn?'el-icon-folder':'el-icon-edit'"
                         v-if="userPermissions.indexOf('lb_schedule_ack_edit')>-1">
                <i v-show='tableLoading' class='el-icon-loading'></i> {{ showSaveBtn ? '保存' : '修改' }}
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-goods' @click='showProducts'
                         v-if='userPermissions.indexOf("lb_schedule_ack_view_product")>-1'>
                产品库
              </el-button>
            </el-form-item>
            <el-form-item style='float: right'>
              <el-radio-group v-model='cellMode' @input='changCellMode'>
                <el-radio-button label='table'>表格</el-radio-button>
                <el-radio-button label='form'>表单</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-col>

      </el-row>
      <!--        已确认的选品-->
      <div>
        <div class='default-table'>
          <el-table
            ref='tableRef'
            :loading='tableLoading' element-loading-spinner='el-icon-loading'
            element-loading-text='加载中……'
            :data='dataList'
            row-key='id'
            max-height='1000' @selection-change='handleSelectionChange' border>
            <el-table-column label='操作' width='55' align='center' v-if='showSaveBtn'>
              <template slot-scope='{row}'>
                <el-button @click='delProducts(row)' type='text' icon='el-icon-remove-outline'
                           style='font-size: 1.2em' />
              </template>
            </el-table-column>
            <el-table-column type='selection' align='center' width='50'></el-table-column>
            <el-table-column type='index' label='序号' align='center' width='50'></el-table-column>
            <el-table-column label='产品图' prop='cover' width='100' align='center'>
              <template slot-scope='{row}'>
                <product-image :image-url='row.cover_url' />
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' prop='title' label='商品名称' align='center'
                             width='180'></el-table-column>
            <el-table-column v-if='cellMode==="form"' label='基本信息' prop='title' min-width='130'
                             header-align='center'
                             align='left'>
              <template slot-scope='{row}'>
                <div>
                  <span><label class='cell-title'>商品名称：</label>{{ row.title }}</span>
                  <!--                  <br/><span><label class='cell-title'>规格：</label>{{ row.spec_name || '/' }}</span>-->
                  <!--                  <br/><span><label class='cell-title'>日常价：</label>{{ row.price || '/' }}</span>-->
                  <br /><span><label class='cell-title'>类目：</label>{{ row.category || '' }}</span>
                  <!--                  <br/><span><label class='cell-title'>独家机制：</label>{{ row.other_activity || '' }}</span>-->
                  <!--                  <br/><span><label class='cell-title'>合作方式：</label>{{ row.mode_alias || '' }}</span>-->
                  <br /><span><label class='cell-title'>负责人：</label>{{ row.leader_name || '' }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label='可修改信息' header-align='center' align='left' min-width='500' v-if='cellMode==="form"'>
              <template slot-scope='{row}'>
                <el-descriptions title='' :column='4'>
                  <el-descriptions-item label='规格' label-class-name='cell-title'>
                    <el-input v-model='row.spec_name' v-if='showSaveBtn'></el-input>
                    <span v-else>{{ row.spec_name || '' }} </span>
                  </el-descriptions-item>
                  <el-descriptions-item label='日常价' label-class-name='cell-title'>
                    <el-input v-model='row.price' v-if='showSaveBtn'>
                      <template slot='suffix'></template>
                    </el-input>
                    <span v-else>{{ row.price || '' }} </span>
                  </el-descriptions-item>
                  <el-descriptions-item label='直播价' label-class-name='cell-title'>
                    <el-input v-model='row.lb_price' v-if='showSaveBtn'>
                      <template slot='suffix'></template>
                    </el-input>
                    <span v-else>{{ row.lb_price || '' }} </span>
                  </el-descriptions-item>
                  <el-descriptions-item label='赠品' label-class-name='cell-title'>
                    <el-input v-model='row.gift' v-if='showSaveBtn'></el-input>
                    <span v-else>{{ row.gift || '' }} </span>
                  </el-descriptions-item>
                  <el-descriptions-item label='坑位费' label-class-name='cell-title'>
                    <el-input oninput="value=value.replace(/[^0-9.]/g,'')"
                              v-model='row.ad_space_fee' placeholder='必填' v-if='showSaveBtn'></el-input>
                    <span v-else>{{ row.ad_space_fee || '' }} </span>
                  </el-descriptions-item>
                  <el-descriptions-item label='线上佣金(%)' label-class-name='cell-title'>
                    <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model='row.online_cmn' placeholder='必填'
                              v-if='showSaveBtn'></el-input>
                    <span v-else>{{ row.online_cmn || '' }} </span>
                  </el-descriptions-item>
                  <el-descriptions-item label='线下佣金(%)' label-class-name='cell-title'>
                    <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model='row.offline_cmn' placeholder='必填'
                              v-if='showSaveBtn'></el-input>
                    <span v-else>{{ row.offline_cmn || '' }} </span>
                  </el-descriptions-item>
                  <el-descriptions-item label='合作方式' label-class-name='cell-title'>
                    <!--                    <CategoryCascader v-if='showSaveBtn' :identify='`mode`' @cascaderChange='cascaderModeChange'-->
                    <!--                                      :selected-list.sync='selectedList'/>-->
                    <el-cascader
                      v-if='showSaveBtn'
                      placeholder='选择分类'
                      :options='selectedList'
                      v-model='row.modeArr'
                      show-all-levels
                      :props='cascaderProps'
                      clearable
                      size='mini'
                      @change='cascaderModeChange(row)'
                      filterable>
                    </el-cascader>
                    <span v-else>{{ row.mode_alias || '' }} </span>
                  </el-descriptions-item>
                  <el-descriptions-item label='品牌支持' label-class-name='cell-title'>
                    <el-select v-model='row.brand_support' filterable allow-create placeholder='可自定义'
                               v-if='showSaveBtn'
                               default-first-option
                               clearable>
                      <el-option label='福袋' value='福袋'></el-option>
                      <el-option label='秒杀' value='秒杀'></el-option>
                    </el-select>
                    <span v-else>{{ row.brand_support || '' }} </span>
                  </el-descriptions-item>
                  <el-descriptions-item label='库存/保质期' label-class-name='cell-title'>
                    <el-input v-model='row.stock_info' placeholder='库存/保质期' v-if='showSaveBtn'></el-input>
                    <span v-else>{{ row.stock_info || '' }} </span>
                  </el-descriptions-item>
                  <el-descriptions-item label='是否样品' label-class-name='cell-title'>
                    <el-select v-model='row.sample_info' filterable allow-create placeholder='可自定义'
                               v-if='showSaveBtn'
                               default-first-option
                               clearable>
                      <el-option label='是' value='是'></el-option>
                      <el-option label='否' value='否'></el-option>
                    </el-select>
                    <span v-else>{{ row.sample_info || '' }} </span>
                  </el-descriptions-item>
                  <el-descriptions-item label='选品原因' label-class-name='cell-title'>
                    <el-input v-model='row.reason' placeholder='选品原因' maxlength='100' v-if='showSaveBtn'
                              clearable></el-input>
                    <span v-else>{{ row.reason || '' }} </span>
                  </el-descriptions-item>
                  <el-descriptions-item label='独家机制' label-class-name='cell-title'>
                    <el-input v-model='row.other_activity' placeholder='独家机制' maxlength='100' v-if='showSaveBtn'
                              clearable></el-input>
                    <span v-else>{{ row.other_activity || '' }} </span>
                  </el-descriptions-item>
                  <el-descriptions-item label='备注信息' label-class-name='cell-title'>
                    <el-input v-model='row.remark' placeholder='备注信息' maxlength='100' v-if='showSaveBtn'
                              clearable></el-input>
                    <span v-else>{{ row.remark || '' }} </span>
                  </el-descriptions-item>
                </el-descriptions>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='类目' prop='category_id' min-width='80' align='center'>
              <template slot-scope='{row}'>
                <span>{{ row.category || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='规格' prop='spec_name' align='center' min-width='120'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <el-input v-model='row.spec_name' v-if='showSaveBtn'></el-input>
                <span v-else>{{ row.spec_name || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='合作方式' prop='mode' align='center' min-width='130'>
              <template slot-scope='{row}'>
                <el-cascader
                  v-if='showSaveBtn'
                  placeholder='选择分类'
                  :options='selectedList'
                  v-model='row.modeArr'
                  show-all-levels
                  :props='cascaderProps'
                  clearable
                  size='mini'
                  @change='cascaderModeChange(row)'
                  filterable>
                </el-cascader>
                <span v-else>{{ row.mode_alias || '' }} </span></template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='日常价(元)' prop='price' align='center' min-width='120'>
              <template slot-scope='{row}'>
                <el-input v-model='row.price' v-if='showSaveBtn'></el-input>
                <span v-else>{{ row.price || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='直播价' prop='lb_price' align='center' min-width='140'>
              <template slot-scope='{row}'>
                <el-input v-model='row.lb_price' v-if='showSaveBtn'></el-input>
                <span v-else>{{ row.lb_price || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='赠品' prop='gift' align='center' min-width='160'>
              <template slot-scope='{row}'>
                <el-input v-model='row.gift' v-if='showSaveBtn'></el-input>
                <span v-else>{{ row.gift || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='独家机制' prop='other_activity' align='center' min-width='120'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <el-input v-model='row.other_activity' v-if='showSaveBtn'></el-input>
                <span v-else>{{ row.other_activity || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='坑位费' prop='ad_space_fee'
                             align='center' min-width='110'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <el-input oninput="value=value.replace(/[^0-9.]/g,'')"
                          v-model='row.ad_space_fee' placeholder='必填' v-if='showSaveBtn'></el-input>
                <span v-else>{{ row.ad_space_fee || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='线上佣金(%)' prop='online_cmn' align='center' min-width='110'>
              <template slot-scope='{row}'>
                <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model='row.online_cmn' placeholder='必填'
                          v-if='showSaveBtn'></el-input>
                <span v-else>{{ row.online_cmn || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='线下佣金(%)' prop='offline_cmn' align='center'
                             min-width='110'>
              <template slot-scope='{row}'>
                <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model='row.offline_cmn' placeholder='必填'
                          v-if='showSaveBtn'></el-input>
                <span v-else>{{ row.offline_cmn || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='品牌支持' prop='brand_support' align='center' min-width='120'>
              <template slot-scope='{row}'>
                <el-select v-model='row.brand_support' filterable allow-create placeholder='可自定义' v-if='showSaveBtn'
                           default-first-option
                           clearable>
                  <el-option label='福袋' value='福袋'></el-option>
                  <el-option label='秒杀' value='秒杀'></el-option>
                </el-select>
                <span v-else>{{ row.brand_support || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='库存/保质期' prop='stock_info' align='center' min-width='120'>
              <template slot-scope='{row}'>
                <el-input v-model='row.stock_info' placeholder='库存/保质期' v-if='showSaveBtn'></el-input>
                <span v-else>{{ row.stock_info || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='是否样品' prop='sample_info' align='center' min-width='120'>
              <template slot-scope='{row}'>
                <el-select v-model='row.sample_info' filterable allow-create placeholder='可自定义' v-if='showSaveBtn'
                           default-first-option
                           clearable>
                  <el-option label='是' value='是'></el-option>
                  <el-option label='否' value='否'></el-option>
                </el-select>
                <span v-else>{{ row.sample_info || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='选品原因' prop='reason' align='center' min-width='140'>
              <template slot-scope='{row}'>
                <el-input v-model='row.reason' placeholder='选品原因' maxlength='100' v-if='showSaveBtn'
                          clearable></el-input>
                <span v-else>{{ row.reason || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='负责人' prop='leader_id' align='center' width='120'>
              <template slot-scope='{row}'>
                <span>{{ row.leader_name || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='备注信息' prop='remark' align='center' min-width='160'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <el-input v-model='row.remark' placeholder='备注信息' maxlength='100' v-if='showSaveBtn'
                          clearable></el-input>
                <span v-else>{{ row.remark || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='店铺名称' prop='company_name' align='center' min-width='120'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.company_name || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if='cellMode==="table"' label='店铺评分' prop='company_score' min-width='100'
                             align='center'></el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
    <br />
    <el-card>
      <el-row :gutter='15'>
        <el-col :span='1.5'>
          <el-button type='primary' size='small' @click='handleOriginal' icon='el-icon-circle-check'
                     v-if='canOriginal'>
            初选完成
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <div style='font-size: 1.3em'>已选：<span style='color: red;font-weight: bold'>{{ dataList.length }}</span> 个
          </div>
        </el-col>
        <el-col :span='1.5'>
          <el-button :type='`${info.progress_status>3?"success":"warning"}`' size='small' @click='handleAck'
                     icon='el-icon-circle-check'
                     v-if='info.progress_status===3'
                     :disabled='loading||userPermissions.indexOf("lb_schedule_ack")===-1' :loading='loading'>
            {{ info.progress_status > 3 ? '已确认选品' : '确认完成选品' }}
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <el-button :type='`${info.progress_status>4?"success":"danger"}`' size='small' @click='handleLock'
                     icon='el-icon-lock'
                     v-if='userPermissions.indexOf("lb_schedule_lock_btn")>-1&&info.progress_status===4'
                     :disabled='loading'
                     :loading='loading'>
            {{ info.progress_status === 5 ? '已锁定表格' : '锁定表格' }}
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <export-btn ref='refExportLink' type='primary' :size='`small`' :lint-title='exportTitle'
                      :can-export="userPermissions.indexOf('lb_schedule_ack_export')>-1&&info.progress_status>=4"
                      @export='exportExcel'></export-btn>
        </el-col>
        <!--        <el-col :span='1.5'>-->
        <!--          <export-ppt size='small' :multiple-selection='dataList' :disabled='dataList.length===0'-->
        <!--                      v-if="userPermissions.indexOf('lb_schedule_ack_export_ppt')>-1&&info.progress_status>=4" />-->
        <!--        </el-col>-->
        <el-col :span='1.5'>
          <ExportLbProductPpt size='small' :id='id'
                              v-if="userPermissions.indexOf('lb_schedule_ack_export_ppt')>-1&&info.progress_status>=4"></ExportLbProductPpt>
        </el-col>
      </el-row>
    </el-card>

    <!--    产品库查看-->
    <ProductTable ref='LbProductAckSelect' :edit-mode='true' :visible.sync='addPreCard' :lbObj='info'
                  @saved='init' v-if='info.id'></ProductTable>
    <!--    待确认选品-->
    <el-dialog width='80%' :title='`待确认的初选商品(${awaitDataList.length})`' @close='init()'
               :visible.sync='dialogFormVisible'>
      <div style='width: 100%'>
        <el-row :gutter='15' style='margin-bottom: 10px'>
          <el-col :span='1.5'>
            <el-button type='primary' icon='el-icon-refresh' @click='getAwaitList'>刷新</el-button>
          </el-col>
          <el-col :span='4'>
            <el-button type='success' icon='el-icon-check' @click='tagSelection' :disabled='multiple'>
              确认选品（{{ ids.length }}）
            </el-button>
          </el-col>
        </el-row>
        <div class='default-table'>
          <el-table
            :data='awaitDataList'
            max-height='600' @selection-change='handleSelectionChange' border>
            <el-table-column type='selection' align='center' width='50'></el-table-column>
            <el-table-column type='index' label='序号' align='center' width='50'></el-table-column>
            <el-table-column label='商品名称' prop='title' width='160' header-align='center' align='left'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.title }}</span>
              </template>
            </el-table-column>
            <el-table-column label='产品图' prop='cover' width='70' align='center'>
              <template slot-scope='{row}'>
                <product-image :image-url='row.cover_url' />
              </template>
            </el-table-column>
            <el-table-column label='负责人' prop='leader' align='center' min-width='100'>
              <template slot-scope='{row}'>
                <span>{{ row.leader_name || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='类目' prop='category_id' min-width='80' align='center'>
              <template slot-scope='{row}'>
                <span>{{ row.category || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='规格' prop='spec_name' align='center' min-width='120'
                             show-overflow-tooltip></el-table-column>
            <el-table-column label='合作方式' prop='mode' align='center' min-width='80'>
              <template slot-scope='{row}'>
                <span>{{ row.mode_alias || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='日常价(元)' prop='price' align='center' min-width='120'>
              <template slot-scope='{row}'>
                <span>{{ row.price || '/' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='直播价(元)' prop='lb_price' align='center' min-width='120'>
              <template slot-scope='{row}'>
                <!--              <el-input v-model='row.lb_price' v-if='showSaveBtn'></el-input>-->
                <span>{{ row.lb_price || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column label='赠品' prop='gift' align='center' min-width='120'>
              <template slot-scope='{row}'>
                <!--              <el-input v-model='row.gift' v-if='showSaveBtn'></el-input>-->
                <span>{{ row.gift || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column label='独家机制' prop='other_activity' align='center' min-width='120'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.other_activity || '' }} </span>
              </template>
            </el-table-column>

            <el-table-column label='公司' prop='company_name' align='center' min-width='120' show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.company_name || '' }}</span>
              </template>
            </el-table-column>
            <!--            <el-table-column label="创建人" prop="create_by" min-width="140" align="center"></el-table-column>-->
            <!--            <el-table-column label="修改人" prop="update_by" min-width="140" align="center"></el-table-column>-->
          </el-table>
        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import ProductImage from '../product/ProductImage'
import LogList from '@/pages/lb/components/LogList'
import ProductTable from '@/pages/lb/product_selection/ProductTable'
import ExportPpt from '@/pages/lb/components/ExportPpt'
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import ExportProductPpt from '@/pages/lb/components/ExportProductPpt'
import ExportLbProductPpt from '@/pages/lb/selection_ack/ExportLbProductPpt'
import Sortable from 'sortablejs'

export default {
  name: 'SelectionAckCard',
  components: {
    ExportLbProductPpt,
    ExportProductPpt,
    ExportPpt,
    ProductTable,
    LogList,
    ProductImage,
    ExportBtn,
    ArtistSearch,
    CategoryCascader,
    Sortable
  },
  props: {
    id: {
      type: [Number, String]
    },
    showHeader: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    cascaderProps() {
      return { value: 'id', label: 'display_name', checkStrictly: true, multiple: false }
    },
    canOriginal() {
      //可完成初选的前提：自己负责的排期或者拥有修改负责人（发起人）权限
      return this.userPermissions.indexOf('lb_schedule_original_btn') > -1 && (this.userPermissions.indexOf('lb_schedule_edit_leader') > -1 || this.info.leader_id === this.userInfo.id) && this.info.progress_status === 2
    },
    progressStatusMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.label
      })
      return map
    },
    progressStatusColorMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.color_value
      })
      return map
    },
    exportTitle() {
      return `下载表格`
    },
    dialogTitle() {
      return `${this.$utils.parseTime(this.info.start_at, '{y}-{m}-{d}')} ${this.info.nickname}
                                ${this.info.platform_name || this.info.platform_code} ${this.info.theme}`
    },

    searchTitleOptions() {
      let options = []
      this.dataList.forEach((item) => {
        if (options.indexOf(item.title) === -1)
          options.push(item.title)
      })
      return options
    },
    categoryStatistics() {
      let sData = []
      this.dataList.forEach((item) => {
        let index = sData.findIndex(value => value.category_id === item.category_id)
        if (index === -1)
          if (index === -1)
            sData.push({ category_id: item.category_id, category: item.category, total: 1 })
          else {
            sData[index]['total'] += 1
          }
      })
      return sData
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      showForm: true,
      showNotify: false,
      searchCondition: {},
      rules: {
        nickname: { required: true, message: '请选择红人（KOL）', trigger: 'change' },
        id: { required: true, message: '请选择排期', trigger: 'change' },
        ad_space_fee: { required: true, message: '请输入坑位费', trigger: 'change' }
      },
      searchLeader: '',
      searchProductTitle: '',
      addPreCard: false,
      dataList: [],
      tableLoading: false,
      tipList: [
        '1、流程：确认初选选品 =》初选完成 =》确认完成选品 =》锁定表格',
        '2、锁定表格后才可下载表格和下载产品手卡',
        '3、产品手卡下载方式：1）产品手卡（单）：每个已确认选品单独导出为产品手卡PPT文件，即导出多个PPT文件，这个情况下浏览器可能会询问是否允许，设置允许即可；2）产品手卡（多）：全部已确认选品的产品手卡导出为一个PPT文件'
      ],
      showSaveBtn: false,
      info: {},
      progressStatusOptions: [
        { label: '新创建', value: 1, color_value: '#FFFFFF', class_name: 'status-new' },
        { label: '已发需求', value: 2, color_value: '#67C23A', class_name: 'status-launch' },
        { label: '初选完成', value: 3, color_value: '#409EFF', class_name: 'status-original' },
        { label: '确认完成', value: 4, color_value: '#E6A23C', class_name: 'status-ack' },
        { label: '锁定表格', value: 5, color_value: '#F56C6C', class_name: 'status-lock' }
      ],
      loading: false,
      awaitDataList: [],
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      cellMode: 'form'//'form,
      ,
      selectedList: [],
      searchLeaderOptions: [],
      newIndexList: []

    }
  },
  methods: {

    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.multiple = !selection.length
    },
    modifyBtn() {
      if (!this.showSaveBtn) {
        this.showSaveBtn = true
        this.getSelect()
      } else {
        this.handleConfirm()
      }

    },
    async getSelect() {
      let { list } = await this.$api.getCategoryListByIdentify(`mode-category`)
      this.selectedList = list
    },
    handleConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) {
          this.$message.error('数据验证失败，请检查必填项数据！')
          return
        }
        this.loading = true
        this.saveSelections()
        this.$nextTick(() => {
          this.loading = false
        })
      })
    }
    , async saveSelections() {
      let ids = this.dataList.map((item) => {
        let row = {}
        // row['id'] = item.lb_product_id || null
        row['id'] = item.id || null
        row['product_id'] = item.product_id
        row['price'] = item.price || ''
        row['brand_support'] = item.brand_support || ''
        row['stock_info'] = item.stock_info || ''
        row['sample_info'] = item.sample_info || ''
        row['lb_price'] = item.lb_price || ''
        row['gift'] = item.gift || ''
        row['online_cmn'] = item.online_cmn || ''
        row['offline_cmn'] = item.offline_cmn || ''
        row['reason'] = item.reason || ''
        row['remark'] = item.remark || ''
        row['leader_id'] = item.leader_id || ''
        row['leader_name'] = item.leader_name || ''
        row['ad_space_fee'] = item.ad_space_fee || null
        row['spec_name'] = item.spec_name || null
        //合作方式别名
        row['mode_alias'] = item.mode_alias || null
        //合作方式
        row['mode'] = item.mode || null
        row['price'] = item.price || null
        row['other_activity'] = item.other_activity || null

        return row
      })
      let postData = {
        lb_id: this.id,
        product_rows: ids
      }
      await this.saveProducts(postData)
    },
    async saveProducts(postData) {
      let info = await this.$api.saveLbScheduleProducts(postData)
      if (info) {
        this.$notify.success('保存成功')
        this.tableLoading = false
        this.showSaveBtn = false

      }
    },
    refreshData(val) {
      if (val) {
        this.init()
      }
    },
    async init() {
      await this.getInfo()
      await this.getAckSelectionList()
      if (this.userPermissions.indexOf('lb_schedule_ack_list_all') > -1) {
        await this.getLbLeaderLdOptions()
      }


    },
    async getLbLeaderLdOptions() {
      //获取负责人下拉信息
      let { options } = await this.$api.getLbLeaderLdOptions({ lb_id: this.id })
      this.searchLeaderOptions = options
      //   getLbScheduleOptions
    },
    async getInfo() {
      if (this.id) {
        //获取当前排期基本信息
        let { info } = await this.$api.getLbScheduleInfo(this.id)
        this.info = { ...info }
      }
    },
    async getAckSelectionList() {
      this.tableLoading = true
      //获取当前全部选品
      let params = {}
      if (this.searchCondition.leader_id) {
        params['leader_id'] = this.searchCondition.leader_id
      }
      if (this.userPermissions.indexOf('product_selection_preview') === -1) {
        params['leader_id'] = this.userInfo.id
      }
      params['lb_id'] = this.id
      params['is_ack'] = 'Y'
      let { list } = await this.$api.getLbScheduleAckProducts(params)
      list = list.map((item) => {
        item.modeArr = [item.mode]
        return item
      })
      this.$nextTick(() => {
        this.dataList = [...list]
        //收集ID
        this.newIndexList = []
        this.dataList.forEach(item => {
          this.newIndexList.push(item.id)
        })
      })
      setTimeout(() => {
        this.tableLoading = false
      }, 500)
    },
    async delRow(row) {
      let index = this.dataList.findIndex(value => value.id === row.id)
      if (index !== -1) {
        this.dataList.splice(index, 1)
      }
      this.$emit('del', row)
    },
    async delProducts(row) {
      if (row.id) {
        this.$confirm(`确定删除选品(${row.title})?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let info = await this.$api.delLbScheduleProducts(row.id)
          if (info) {
            this.$message.success('删除成功')
            await this.delRow(row)
          }
        }).catch(() => {
          this.$message.info('已取消删除')
          // this.close()
          return false
        })
      } else {
        await this.delRow(row)
      }
    },
    //导出排期选品
    async exportExcel() {
      try {
        let name = `${this.info.nickname}_${this.info.platform_name}_${this.$utils.parseTime(this.info.start_at, '{y}-{m}-{d}')}直播排期选品`
        let queryParams = {
          lb_id: this.id,
          is_ack: 'Y'
        }
        let response = await this.$api.exportLbScheduleSelection(queryParams)
        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    hideTotalDiv() {
      this.showForm = false
    },
    showProducts() {
      this.addPreCard = true

    },
    async showPreCard() {
      await this.getAwaitList()
      this.dialogFormVisible = true
    },
    async getAwaitList() {
      let params = {}
      params['lb_id'] = this.info.id
      params['is_ack'] = 'N'
      let { list } = await this.$api.getLbScheduleAckProducts(params)
      this.$nextTick(() => {
        this.awaitDataList = list || []
      })
    },
    async handleOriginal() {
      this.$confirm(`此操作将【完成初选】，是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.originalLbSchedule(this.id)
        await this.init()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })


    },
    async handleAck() {
      let total = this.dataList.length
      this.$confirm(`当前确认选品：${total}个,确认完成选品后，将不能修改选品,是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.loading = true
        await this.$api.ackLbSchedule(this.id)
        setTimeout(() => {
          this.loading = false
        }, 500)
        this.init()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async handleLock() {
      let total = this.dataList.length
      this.$confirm(`已确认选品：${total}个，锁定表格后可下载，是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.loading = true
        await this.$api.lockLbSchedule(this.id)
        setTimeout(() => {
          this.loading = false
        }, 500)
        await this.init()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async tagSelection() {
      //选择的标记为Y
      let info = await this.$api.tagLbScheduleProducts(this.ids)
      if (info === 'ok') {
        this.msgSuccess('操作成功')
        // 刷新待选列表
        setTimeout(() => {
          this.getAwaitList()
        }, 500)

      }
    },
    async cancelTagSelection() {
      //选择的标记为Y
      let info = await this.$api.cancelTagLbScheduleProducts(this.ids)
      if (info === 'ok') {
        this.msgSuccess('操作成功')
        // 刷新待选列表
        setTimeout(() => {
          this.getAckSelectionList()
        }, 500)
      }
    },
    changCellMode(val) {
      this.showSaveBtn = false
      localStorage.setItem('LB_CELL_MODE', val)
    },
    getCellMode() {
      this.cellMode = localStorage.getItem('LB_CELL_MODE') || 'table'
    },
    cascaderModeChange(row) {
      let modeArr = row.modeArr
      let mode = modeArr[modeArr.length - 1]
      let data = this.selectedList.find(value => value.id === mode)
      if (data)
        row.mode_alias = data.display_name || ''

      row.mode = mode
    },
    initSortableList() {
      let el = this.$refs.tableRef.$el.querySelector('.el-table__body-wrapper tbody')
      //设置配置
      let _this = this
      Sortable.create(el, {
        animation: 150,
        sort: true,
        draggable: '.el-table__row', // 设置可拖拽行的类名(el-table自带的类名)
        onEnd({ newIndex, oldIndex }) {
          let currRow = _this.newIndexList.splice(oldIndex, 1)[0]
          _this.newIndexList.splice(newIndex, 0, currRow)
          _this.dragSortSubmit()
        }
      })
    },
    async dragSortSubmit() {
      await this.$api.saveScheduleOrder({ ids: this.newIndexList })
    }
  },
  async mounted() {
    await this.init()
    document.body.addEventListener('drop', (event) => {
      event.preventDefault()
      event.stopPropagation()
    }, false)
    this.initSortableList()


  },
  created() {
    this.getCellMode()

  }
}
</script>

<style scoped>
.total-card {
  background: #0C0C0C;
  opacity: 0.8;
  padding: 20px;
}

.total-form {
  /*background: #f2f2f2;*/
  padding: 20px 20px;
  border-radius: 5px;
}


</style>
<style>
.cell-title {
  font-size: 1.1em;
  font-weight: bold;
  margin-left: 5px;
}
</style>
