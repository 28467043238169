<template>
  <div>
    <el-button title='排期已确认的选品手卡提报' v-bind='$attrs' icon='el-icon-download' @click='exportPpt' :loading='loading'
               :disabled='loading'>
      导出手卡
    </el-button>
  </div>
</template>

<script>
import FileSaver from 'file-saver'

export default {
  name: 'ExportLbProductPpt',
  props: {
    //排期的ID
    id: {
      type: [Number, String]
    },
    searchCondition: {
      type: Object
    },
    tableName: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    handleSearchCondition() {
      let cond = {}
      if (this.searchCondition.category_ids) {
        cond['category_ids'] = this.searchCondition.category_ids
      }
      if (this.searchCondition.title) {
        cond['title'] = this.searchCondition.title
      }
      if (this.searchCondition.leader_id) {
        cond['leader_id'] = this.searchCondition.leader_id
      }
      if (this.searchCondition.is_ack) {
        cond['is_ack'] = this.searchCondition.is_ack
      }
      if (this.searchCondition.state) {
        cond['state'] = this.searchCondition.state
      }
      if (this.searchCondition.ids) {
        cond['ids'] = this.searchCondition.ids
      }
      // //判断有没有保存全部的权限
      // if (!(this.userPermissions.indexOf('product_selection_preview') > -1)) {
      //   this.searchCondition.leader_name = this.leader.leader_name
      //   this.searchCondition.leader_id = this.leader.leader_id
      // }
      if (this.searchCondition.lb_id) {
        cond['lb_id'] = this.searchCondition.lb_id
      } else {
        //归属排期
        // cond['lb_id'] = this.id
        this.$notify.info('排期ID必传')
      }
      return cond
    },
    async exportPpt() {
      let title = '缇苏直播手卡提报'
      let isFileSaverSupported = !!new Blob
      if (isFileSaverSupported) {

        if (this.id) {
          this.loading = true
          setTimeout(() => {
            this.loading = false
          }, 2500)
          let searchCondition = this.handleSearchCondition()
          let response = await this.$api.exportLbProductPpt(searchCondition)
          let data = response.data
          let contentType = response.headers['content-type']
          let blob = new Blob([data], {
            type: contentType
          })

          FileSaver.saveAs(blob, decodeURI(encodeURI(title) + '.pptx'), { autoBom: true })
          this.$message.success('导出成功')
          setTimeout(() => {
            this.loading = false
          }, 500)

        } else {
          this.msgWarn('请先选择排期')
        }

      } else {
        this.$message.warning('浏览器不支持导出文件')
      }
    }
  }
}
</script>

<style scoped>

</style>