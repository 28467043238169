<template>
  <div class='main-page-content'>
    <SelectionAckCard :id='id' v-if='id'></SelectionAckCard>
    <br />
    <!--    日志-->
    <el-row v-if='userPermissions.indexOf("lb_schedule_log")>-1'>
      <el-col :span='24'>
        <div>
          <el-link type='primary' icon='el-icon-document' @click='showLog'>查看下载记录</el-link>
          <div style='width: 100%;height: 500px' v-if='showLogDiv'>
            <LogList ref='refLogList' :op-type='logType' :info-id='id' v-if='id' style='width: 100%' />
          </div>
        </div>
      </el-col>
    </el-row>
    <div>
      <Tips :data='tipList' />
    </div>
  </div>
</template>

<script>
import SelectionAckCard from '@/pages/lb/selection_ack/SelectionAckCard'
import { mapGetters } from 'vuex'

import LogList from '@/pages/lb/components/LogList'

export default {
  name: 'SelectionAckIndex',
  components: { LogList, SelectionAckCard },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    canOriginal() {
      //可完成初选的前提：自己负责的排期或者拥有修改负责人（发起人）权限
      return this.userPermissions.indexOf('lb_schedule_original_btn') > -1 && (this.userPermissions.indexOf('lb_schedule_edit_leader') > -1 || this.info.leader_id === this.userInfo.id) && this.info.progress_status === 2
    },
    progressStatusMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.label
      })
      return map
    },
    progressStatusColorMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.color_value
      })
      return map
    },
    exportTitle() {
      return `下载表格`
    },
    dialogTitle() {
      return `${this.$utils.parseTime(this.info.start_at, '{y}-{m}-{d}')} ${this.info.nickname}
                                ${this.info.platform_name || this.info.platform_code} ${this.info.theme}`
    },
    searchLeaderOptions() {
      let options = []
      this.dataList.forEach((item) => {
        if (options.indexOf(item.leader_name) === -1)
          options.push(item.leader_name)
      })
      return options
    },
    searchTitleOptions() {
      let options = []
      this.dataList.forEach((item) => {
        if (options.indexOf(item.title) === -1)
          options.push(item.title)
      })
      return options
    },
    categoryStatistics() {
      let sData = []
      this.dataList.forEach((item) => {
        let index = sData.findIndex(value => value.category_id === item.category_id)
        if (index === -1)
          if (index === -1)
            sData.push({ category_id: item.category_id, category: item.category, total: 1 })
          else {
            sData[index]['total'] += 1
          }
      })
      return sData
    }
  },
  data() {
    return {
      id: null,
      tipList: [
        '1、流程：确认初选选品 =》初选完成 =》确认完成选品 =》锁定表格',
        '2、锁定表格后才可下载表格和下载产品手卡',
        '3、产品手卡下载方式：1）产品手卡（单）：每个已确认选品单独导出为产品手卡PPT文件，即导出多个PPT文件，这个情况下浏览器可能会询问是否允许，设置允许即可；2）产品手卡（多）：全部已确认选品的产品手卡导出为一个PPT文件'
      ],
      showLogDiv: false,
      //日志类型
      logType: 'selection_export'
    }
  },
  methods: {
    showLog() {
      this.showLogDiv = !this.showLogDiv
    }
  },
  created() {
    this.id = this.$route.params.id || this.$route.query.id
  }
}
</script>

<style scoped>

</style>